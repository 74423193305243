export class DisplayedTrack {
    constructor(maps, from, to, events, color, tail) {//path1, path2) {
        this.from = from;
        this.to = to;
        this.tail = tail
        this.trackline = new TrackLine(maps, events, color, tail);
    }

    clear() {
        this.trackline.clear();
    }

    before(points) {
        this.trackline.before(points);
    }

    after(pos) {
        this.trackline.after(pos);
    }

    refresh(points) {
        this.from = points[0].sent;
        this.to = points[points.length - 1].sent;
        this.trackline.refresh(points);
    }

    isAfter(ts) {
        return ts.getTime() < this.from.getTime();
    }
}

class DoubleLine {
    constructor(pts, color) {
        // create a simple form of an identity value
        this._id = pts.reduce((v, ll) => {
            return v + ll.lat() + ll.lng()
        }, 0)

        this._points = pts;
        this._color = color;
    }

    show(map) {
        this.clear();
        this._backingLine = new google.maps.Polyline({
            path: this._points,
            geodesic: true,
            strokeColor: "#FFFFFF",
            strokeOpacity: 1.0,
            strokeWeight: 4,
            map: map,
        });
        this._frontLine = new google.maps.Polyline({
            path: this._points,
            geodesic: true,
            strokeColor: this._color,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            map: map,
        })
    }

    clear() {
        if (this._backingLine) this._backingLine.setMap(null);
        if (this._frontLine) this._frontLine.setMap(null);
    }

    before(points) {
        const pt1 = this._backingLine.getPath();
        const pt2 = this._frontLine.getPath();
        points.map(p => {
            if (p.position) {
                pt1.insertAt(0, p.position);
                pt2.insertAt(0, p.position);
            }
            return p;
        });
        this._backingLine.setPath(pt1);
        this._frontLine.setPath(pt2);
    }

    after(points) {
        const pt1 = this._backingLine.getPath();
        const pt2 = this.pa_frontLineth2.getPath();
        points.map(p => {
            if (p.position) {
                pt1.push(new google.maps.LatLng(p.position));
                pt2.push(new google.maps.LatLng(p.position));
            }
            return p;
        });
        this._backingLine.setPath(pt1);
        this._frontLine.setPath(pt2);
    }
}

class TrackLine {
    constructor(map, evts, color, tail) {
        this._maps = map;
        this.color = color;
        this._dlines = [];
        this._markers = {};
        this.tail = tail;

        this._createLines(map, evts)
        this._id = new Date().getTime();
    }

    createMarker(map, latLng, label, color, opac, begin) {
        const icon = {
            path: begin ? google.maps.SymbolPath.FORWARD_CLOSED_ARROW : google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
            scale: 5.5,
            fillColor: color,
            fillOpacity: opac,
            strokeWeight: 1,
            strokeColor: "#000"
        };
        if (begin) {
            icon.strokeColor = "#fff";
        }

        return new google.maps.Marker({
            position: latLng,
            title: label,
            map: map,
            icon,
        });
    }

    _createLines(map, evts) {
        let tracks = [];
        let curtrk = [];
        let markers = {};

        const oldmarkers = { ...this._markers }
        for (let e of evts) {
            if (e.code == "PONG") continue;
            const key = e.sent.getTime();
            if (e.position && !e.position.invalid && e.position.gpsfix > 1) {
                let latLng = new google.maps.LatLng(e.position);

                if (curtrk.length == 0) {
                    if (this.tail == 0) {
                        const oldm = this._markers[key];
                        if (oldm) {
                            markers[key] = oldm
                        } else {
                            const lbl = `Start: ${e.sent.toLocaleString()}`
                            markers[key] = this.createMarker(map, latLng, lbl, this.color, 0.5, true);
                        }
                    }
                }
                curtrk.push(latLng);
                if (e.code == "STOP" || e.code == "POWER-OFF") {
                    tracks.push(curtrk);
                    if (this.tail == 0) {
                        const oldm = this._markers[key];
                        if (oldm) {
                            markers[key] = oldm
                        } else {
                            const lbl = `End: ${e.sent.toLocaleString()}`
                            markers[key] = this.createMarker(map, latLng, lbl, this.color, 0.5, false);
                        }
                    }
                    curtrk = [];
                }
            }
        }
        if (curtrk.length > 0) tracks.push(curtrk);
        this._markers = markers;
        // remove old markers
        Object.keys(oldmarkers).filter(m => markers[m] == null).forEach(m => oldmarkers[m].setMap(null));

        const newDlines = []
        // copy all current dlines so we can remove the ones we want to display again
        const oldlines = this._dlines.slice();
        for (let i = 0; i < tracks.length; i++) {
            const t = tracks[i]
            const dline = new DoubleLine(t, this.color);
            if (this._dlines.length > i) {
                const olddl = this._dlines[i];
                if (olddl._id == dline._id) {
                    newDlines.push(olddl)
                    // rmove this line from the oldlines
                    oldlines[i] = null;
                    continue
                }
            }
            dline.show(map);
            newDlines.push(dline);
        }
        this._dlines = newDlines;
        // all lines in oldlines are ... well old ones and should be removed from map
        oldlines.filter(l => l != null).forEach(l => l.clear())
    }

    clear() {
        this._dlines.forEach(dl => dl.clear());
        Object.values(this._markers).forEach(m => m.setMap(null));
        this._dlines = [];
        this._markers = {};
    }

    before(points) {
        this._dlines.forEach(dl => dl.before(points));
    }

    after(points) {
        this._dlines.forEach(dl => dl.after(points));
    }

    refresh(points) {
        //this.clear();
        this._createLines(this._maps, points);
    }

}